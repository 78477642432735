<template>
  <div class="flex flex-col flex-grow">
    <!-- intro block -->
    <div class="relative flex flex-col bg-slate-900 h-screen overflow-x-hidden">
      <MultiCube class="z-10 absolute mt-80 rotate-45 -ml-[216px]" />
      <div
        class="invisible opacity-10 lg:visible w-[50%] h-[1021px] absolute right-0 bg-cover bg-right bg-[url('assets/bg-code.jpg')]"
      />
      <div class="absolute z-20 w-full">
        <div class="flex flex-grow flex-row-reverse">
          <a
            href="mailto:contact@i2.xyz"
            class="hover:bg-sky-400 cursor-pointer px-6 h-12 flex items-center justify-center bg-sky-900 text-slate-50 uppercase text-xs font-semibold"
          >
            Contact Us
          </a>
          <div
            class="hidden sm:block h-12 flex pt-3 items-center justify-center px-6 font-semibold font-robot text-slate-400/70"
          >
            &copy; i² technologies
          </div>
          <div
            class="flex-grow flex flex-row mt-14 ml-4 sm:ml-20 font-robot text-slate-50"
          >
            <img alt="logo" class="h-10 sm:h-14" src="../assets/logo.png" />
            <div class="sm:block hidden flex flex-col ml-5 h-12 justify-center">
              <div class="h-10 flex items-center text-slate-200 text-2xl">
                <span class="text-slate-50">i</span>
                <span class="text-sky-400">²</span>
              </div>
              <div
                class="text-xs h-10 -mt-[20px] flex items-center text-slate-400"
              >
                technologies
              </div>
            </div>
          </div>
        </div>

        <div
          class="sm:mt-48 mt-24 sm:mx-24 mx-4 lg:mx-48 space-y-16 flex flex-col"
        >
          <div class="flex flex-row">
            <h1
              class="text-5xl sm:text-6xl font-robot font-semibold text-slate-50"
            >
              Pushing boundaries of the possible.
            </h1>
          </div>

          <p class="font-robot text-2xl text-slate-50 w-full lg:w-[45%]">
            i² is a technology company providing liquidity in global financial
            markets, dedicated to enhance price discovery by strictly adhering
            to mathematical and statistical methods.
          </p>

          <div class="flex flex-row space-x-6">
            <a
              href="mailto:contact@i2.xyz"
              class="flex flex-row cursor-pointer group"
            >
              <div
                class="flex border border-slate-900 items-center bg-sky-400 p-4 px-5 text-slate-50 uppercase text-xs font-semibold"
              >
                &gt;
              </div>
              <div
                class="flex border-t border-b border-r border-slate-900 items-center bg-sky-900 group-hover:bg-sky-400 p-4 px-5 text-slate-50 uppercase text-xs font-semibold"
              >
                Contact us
              </div>
            </a>
            <!--<div class="flex flex-row">
              <div
                class="flex items-center bg-sky-400 p-4 px-5 text-slate-50 uppercase text-xs font-semibold"
              >
                &gt;
              </div>
              <div
                class="flex items-center bg-sky-900 p-4 px-5 text-slate-50 uppercase text-xs font-semibold"
              >
                Careers
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiCube from "@/components/MultiCube";
export default {
  name: "HomeView",
  components: { MultiCube },
};
</script>

<style scoped></style>
