<template>
  <div class="flex flex-col">
    <div class="grid grid-cols-3">
      <div
        class="h-36 w-36 bg-sky-400 border-r border-b border-slate-900"
      ></div>
      <div class="h-36 w-36 bg-sky-500 border-b border-slate-900"></div>
      <div
        class="h-36 w-36 bg-sky-600 border-l border-b border-slate-900"
      ></div>
    </div>
    <div class="grid grid-cols-3">
      <div class="h-36 w-36 bg-sky-500 border-r border-slate-900"></div>
      <div class="h-36 w-36 bg-sky-400"></div>
      <div class="h-36 w-36 bg-sky-500 border-l border-slate-900"></div>
    </div>
    <div class="grid grid-cols-3">
      <div
        class="h-36 w-36 bg-sky-600 border-t border-r border-slate-900"
      ></div>
      <div class="h-36 w-36 bg-sky-500 border-t border-slate-900"></div>
      <div
        class="h-36 w-36 bg-sky-400 border-l border-t border-slate-900"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiCube",
};
</script>

<style scoped></style>
